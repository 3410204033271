import { useState } from 'react';
import HelpText from 'components/HelpText';
import { Button, FormControlLabel, makeStyles, Switch } from '@material-ui/core';
import NumberInput from 'components/Input/NumberInput';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { SettingsState } from 'redux/ducks/settings';

interface LockedRegistrationDaysProps {
  settings: Pick<
    SettingsState,
    'enableLockedRegistrations' | 'lockedByAdmin' | 'overrideChildSettings'
  >;
  onUpdateSettings: (data) => void;
  hasSubscribedAccounts: boolean;
}

type OwnProps = LockedRegistrationDaysProps;

const useStyles = makeStyles(() => {
  return {
    editDays: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: '36px',
      marginBottom: '12px'
    },
    formLabel: {
      marginRight: '12px',
      fontWeight: 600
    },
    submit: {
      marginLeft: '16px',
      marginRight: '4px'
    }
  };
});

const LockedRegistrationDays: React.FunctionComponent<OwnProps> = ({
  settings,
  onUpdateSettings,
  hasSubscribedAccounts
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const { enableLockedRegistrations, lockedByAdmin, overrideChildSettings } = settings;
  const { enableLockedRegistrations: hasLockedRegistrations } = lockedByAdmin || {};
  const [days, setDays] = useState(enableLockedRegistrations ?? 30);

  const handleUpdateSettings = (days?: number) => {
    let updateSettings: Partial<SettingsState> = {
      enableLockedRegistrations: days
    };

    if (!days && overrideChildSettings?.enableLockedRegistrations?.enabled) {
      updateSettings = {
        ...updateSettings,
        overrideChildSettings: {
          ...overrideChildSettings,
          enableLockedRegistrations: {
            enabled: false,
            overridePrevious: true
          }
        }
      };
    }

    onUpdateSettings(updateSettings);
  };

  const handleOverrideSubAccounts = () => {
    onUpdateSettings({
      overrideChildSettings: {
        ...overrideChildSettings,
        enableLockedRegistrations: {
          enabled: Boolean(enableLockedRegistrations)
        }
      }
    });
  };

  return (
    <div className={'settingToggle'}>
      <div>
        <HelpText
          helpText={intl.formatMessage({ id: 'help.settings.enableLockedRegistrations' })}
        />
        <FormControlLabel
          control={
            <Switch
              color={'primary'}
              disabled={hasLockedRegistrations}
              checked={Boolean(enableLockedRegistrations)}
              onChange={(e, isInputChecked) => {
                handleUpdateSettings(isInputChecked ? days : null);
                if (!isInputChecked) {
                  setDays(30);
                }
              }}
            />
          }
          label={intl.formatMessage({ id: 'settings.enableLockedRegistrations' })}
        />
      </div>
      {enableLockedRegistrations && (
        <div className={classes.editDays}>
          <FormControlLabel
            labelPlacement='start'
            classes={{
              label: classes.formLabel
            }}
            control={
              <NumberInput
                allowNegative={false}
                name='days'
                value={days}
                disabled={hasLockedRegistrations}
                onChange={(e) => setDays(Number(e.target.value))}
              />
            }
            label={intl.formatMessage({ id: 'settings.enableLockedRegistrations.lockedDays' })}
          />
          <Button
            className={classes.submit}
            color='primary'
            variant='contained'
            disabled={hasLockedRegistrations}
            onClick={() => handleUpdateSettings(days)}
          >
            {intl.formatMessage({ id: 'base.save' })}
          </Button>
          {hasSubscribedAccounts && (
            <Button
              disabled={days !== enableLockedRegistrations || hasLockedRegistrations}
              color='primary'
              variant='contained'
              onClick={handleOverrideSubAccounts}
            >
              {intl.formatMessage({ id: 'settings.targets.applyToSubaccounts' })}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default LockedRegistrationDays;
